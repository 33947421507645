import React from "react"

class Background extends React.Component {
    componentDidMount() {

        let c = this.refs.canvas;
        
        c.width =  window.innerWidth;
        c.height =  window.innerHeight;
 let ctx = c.getContext('2d');

window.onresize = resize;

/*-----------------------*/

let dots = [];
let colW = 80;
let lineH = 80;
let nbCols;
let nbLines;
let nbDots;
let t = 0;
let mouse = {x: window.innerWidth * 0.5, y: window.innerHeight * 0.5};
let action = {x: window.innerWidth * 0.5, y: window.innerHeight * 0.5};
let zoneRadius;
let zoneStep = 20;

function start(){
    draw();
}

let Dot = function(x, y){
    this.x = x;
    this.y = y;
    this.ax = x;
    this.ay = y;
};

function createDots(){
    let w = c.width;
    let h = c.height;
    nbCols = ~~(w / colW) + 2;
    nbLines = ~~(h / lineH) + 2;
    nbDots = nbCols * nbLines;
    let decalX = -17;
    let decalY = -10;
    dots = [];

    for(let i = 0; i < nbLines; i++) {
        for(let j = 0; j < nbCols; j++) {
            dots.push(new Dot(decalX + j * colW, decalY + i * lineH));
        }
    }
}

function drawDots(anchors, color, radius){
    let dot;
    let dist;
    for(let i = 0; i < nbDots; i++) {
        dot = dots[i];
        dist = getDistance(dot, action);
        ctx.globalAlpha = Math.max(1 - (dist / (zoneRadius * 1.2)), 0);;
        ctx.beginPath();
        if(anchors) {
            ctx.moveTo(dot.ax, dot.ay);
            ctx.arc( dot.ax, dot.ay, radius, 0, Math.PI * 2, true);
        }
        else {
            ctx.moveTo(dot.x, dot.y);
            ctx.arc( dot.x, dot.y, radius, 0, Math.PI * 2, true);
        }
        ctx.closePath();
        ctx.fillStyle = color;
        ctx.fill();
    }
}

function drawLines(color){
    let dot, nextDot, col, line, dist;
    for(let i = 0; i < nbDots; i++) {
        line = ~~(i / nbCols);
        col = i % nbCols;

        dot = dots[i];
        dist = getDistance(dot, action);
        ctx.globalAlpha = Math.max(1 - (dist / (zoneRadius * 1.2)), 0.05);
        ctx.beginPath();
        if(line < (nbLines - 1)){
            nextDot = dots[i + nbCols];
            ctx.moveTo(dot.x, dot.y);
            ctx.lineTo(nextDot.x, nextDot.y);
        }
        if(col < (nbCols - 1)) {
            nextDot = dots[i + 1];
            ctx.moveTo(dot.x, dot.y);
            ctx.lineTo(nextDot.x, nextDot.y);
        }
        ctx.closePath();
        ctx.strokeStyle = color;
        ctx.stroke();
    }
}

function drawJoints(color){
    let dot, nextDot, col, lin, dist;
    for(let i = 0; i < nbDots; i++) {
        dot = dots[i];
        dist = getDistance(dot, action);
        ctx.globalAlpha = Math.max(1 - (dist / (zoneRadius * 1.2)), 0.05);
        ctx.beginPath();

        ctx.moveTo(dot.x, dot.y);
        ctx.lineTo(dot.ax, dot.ay);

        ctx.closePath();
        ctx.strokeStyle = color;
        ctx.stroke();
    }
}

function getDistance(dot1, dot2) {
    return Math.sqrt((dot2.x - dot1.x) * (dot2.x - dot1.x) + (dot2.y - dot1.y) * (dot2.y - dot1.y));
}

function moveDots() {
    let dot, dist, angle;
    for(let i = 0; i < nbDots; i++) {
        dot = dots[i];
        angle = -Math.atan2(action.x - dot.ax, action.y - dot.ay) - (Math.PI * 0.5);
        dist = getDistance(dot, action);

        if(dist <= zoneRadius) {
            dot.x = dot.ax + zoneStep * (1 - (dist / zoneRadius)) * Math.cos(angle);
            dot.y = dot.ay + zoneStep * (1 - (dist / zoneRadius)) * Math.sin(angle);
        }
        else {
            dot.x = dot.ax;
            dot.y = dot.ay;
        }
    }
}


function resize(){
    let w = window.innerWidth;
    let h = window.innerHeight;
    c.width = w;
    c.height = h;
    zoneRadius = Math.min(c.width * 0.2, 250);
    createDots();
}

function draw(){
    requestAnimationFrame(draw);
    render();
}

function render(){
    t += 0.006;

    mouse.x = (Math.cos(t + 1) + 1) * 0.5 * c.width * (0.5 * (Math.cos(t + 12) + 2)) * 0.5 + c.width * 0.25;
    mouse.y = (Math.sin(t) + 1) * 0.5 * (0.5 * (Math.cos(t * 3 + 4) + 1)) * c.height * 0.5 + c.height * 0.25;

    ctx.clearRect(0, 0, c.width, c.height);
    action.x += (mouse.x - action.x) ;
    action.y += (mouse.y - action.y) ;
    moveDots();
    drawLines("#666");
    drawJoints("#666");
    drawDots(true, "gold", 1);
}

resize();
start();

        
    }
    
    render() {
      return(
        <div>
            <canvas ref="canvas" style={{position:'fixed',top:'0',left:'0',zIndex:'0'}}/>
        </div>
      )
    }

  }
  export default Background

