import React from "react"
import styled from "styled-components"

import { TextBody, TextSmall } from "../Typography"
import Link from "../Link"
import { Box } from "../Box"

const Separator = styled.span`
  width: 42px;
  display: block;
  margin: 13px 0;
  height: 1px;
  background-color:var(--textDesc);
`

const Title = styled(TextSmall)`
  display: block;
  letter-spacing: ${props => props.theme.letterSpacing.wide};
  color:var(--textDesc);
  line-height: 1.166666666;
`

const Info = styled(Link)`
  font-weight: 400;
  line-height: 1.5;
  display: block;
  margin-top: 13px;
  color:var(--textNormal);
  @media (max-width: 414px) {
    font-size: ${props => props.theme.fontSize["xs"]};
  }
`

const OverviewInfo = () => (
  <Box
    display="flex"
    justifyContent="space-between"
    marginTop={16}
    maxWidth="475px"
  >
    <Box>
      <Separator />
      <Title color="silver">工作</Title>
      <Info color="white" href="http://cdc.tencent.com/" target="_blank" >
      腾讯.用户研究与体验设计部
      </Info>
    </Box>
    <Box>
      <Separator />
      <Title color="silver">生活</Title>
      <Info
        color="white"
        target="_blank"
        href="https://map.qq.com/?l=41b4603cb920eac48a5b510cdd0bdabc"
      >
        广东.深圳
      </Info>
    </Box>
    <Box>
      <Separator />
      <Title color="silver">互联网</Title>
      <Info
        color="white"
        target="_blank"
        href="https://weibo.com/avenirzheng"
      >
        @avenirzheng
      </Info>
    </Box>
  </Box>
)

export default OverviewInfo
