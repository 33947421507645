import React from "react"
import styled from "styled-components"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import OverviewInfo from "../components/OverviewInfo"
import Background from "../components/Background"
import { Box } from "../components/Box"
import Link from "../components/Link"

const Introduction = styled.h1`
  color: var(--textTitle);
  font-family: ${props => props.theme.fontFamily.default};
  font-size: ${props => props.theme.fontSize["4xl"]};
  font-weight: 400;
  line-height: 1.5;
  overflow-wrap: break-word;
  max-width: 820px;

  @media (max-width: 414px) {
    font-size: ${props => props.theme.fontSize["xl"]};
  }
`

const IndexPage = () => (
  <Layout>
    <SEO title="主页" />
    <Box
      element="section"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Introduction>
      您好，我叫阿义，负责 <Link href="http://design.tencent.com/"  underlined target="_blank"
>腾讯设计云</Link> 和腾讯投资并购系统，目前担任 CDC 墨子工作室总监。
      </Introduction>
      
      <OverviewInfo />
    </Box>
    <Background />
  </Layout>
)

export default IndexPage
